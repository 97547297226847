const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1160,
    xl: 1280,
  },
}

export default breakpoints
