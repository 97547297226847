const Drawer = {
  paper: {
    width: 256,
  },
  paperAnchorDockedLeft: {
    borderRight: 0,
  }
}

export default Drawer
