import breakpoints from './breakpoints'
import palette from './palette'
import shadows from './shadows'
import shape from './shape'
import typography from './typography'

const styles = {
  breakpoints,
  palette,
  shadows,
  shape,
  typography,
}

export default styles
