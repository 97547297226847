const Container = {
  maxWidth: 1100,
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: 15,
  paddingRight: 15,
}

const ContainerSmall = {
  ...Container,
  maxWidth: 800,
}

export { Container, ContainerSmall }
