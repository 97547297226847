import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Hidden from '@material-ui/core/Hidden'
import Navigator from './Navigator'
import Content from './Content'
import Header from './Header'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    background: '#E9ECEF',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 256,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}))

const Paperbase = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const classes = useStyles()

  const handleDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen)
  }


  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            variant="temporary"
            open={isMobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator />
        </Hidden>
      </nav>
      <div className={classes.appContent}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.mainContent}>
          <Content />
        </main>
      </div>
    </div>
  )
}

export default Paperbase
