import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
 } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual'
import PublicIcon from '@material-ui/icons/Public'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import TimerIcon from '@material-ui/icons/Timer'
import SettingsIcon from '@material-ui/icons/Settings'
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import Logo from 'components/Logo/Logo'


const useStyles = makeStyles(theme => ({
  list: {
    background: theme.palette.navy.dark,
    minHeight: '100vh',
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: theme.palette.navy.text,
    '&:hover, &:focus': {
      background: theme.palette.navy.primary,
    },
  },
  itemCategory: {
    backgroundColor: theme.palette.navy.primary,
    boxShadow: `0 -1px 0 ${theme.palette.navy.divider} inset`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2.5),
    color: theme.palette.navy.text,
  },
  itemActiveItem: {
    color: theme.palette.secondary.main,
  },
  icon: {
    width: 18,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

const Navigator = props => {
  const classes = useStyles()
  const categories = [
    {
      id: 'Ratings',
      children: [
        { id: 'Authentication', icon: <PeopleIcon className={classes.icon} />, active: true },
        { id: 'Database', icon: <DnsRoundedIcon className={classes.icon} /> },
        { id: 'Storage', icon: <PermMediaOutlinedIcon className={classes.icon} /> },
        { id: 'Hosting', icon: <PublicIcon className={classes.icon} /> },
        { id: 'Functions', icon: <SettingsEthernetIcon className={classes.icon} /> },
        { id: 'ML Kit', icon: <SettingsInputComponentIcon className={classes.icon} /> },
      ],
    },
    {
      id: 'Reviews',
      children: [
        { id: 'Analytics', icon: <SettingsIcon className={classes.icon} /> },
        { id: 'Performance', icon: <TimerIcon className={classes.icon} /> },
        { id: 'Test Lab', icon: <PhonelinkSetupIcon className={classes.icon} /> },
      ],
    },
  ]

  return (
    <Drawer variant="permanent" {...props}>
      <List className={classes.list} disablePadding>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <Logo />
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.itemPrimary }}>
            Dashboard
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText classes={{ primary: classes.categoryHeaderPrimary }}>
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item, active && classes.itemActiveItem)}
              >
                <ListItemIcon className={clsx(classes.itemIcon, active && classes.itemActiveItem)}>
                  {icon}
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.itemPrimary }}>
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  )
}

export default Navigator
