const fontFamily = '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif'
const fontSize = 14
const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700
const fontWeightBlack = 800
const htmlFontSize = 16
const coef = htmlFontSize / 14
const caseAllCaps = { textTransform: 'uppercase' }
const noTransform = { textTransform: 'none' }
// const marginBottom = { marginBottom: '1rem' }

const pxToRem = value => `${(value / htmlFontSize) * coef}rem`
const round = value => Math.round(value * 1e5) / 1e5
const buildVariant = (fontWeight, size, lineHeight, letterSpacing, other) => ({
  fontFamily,
  fontWeight,
  fontSize: pxToRem(size),
  lineHeight,
  letterSpacing: `${round(letterSpacing / size)}em`,
  ...other,
})

const variants = {
  h1: buildVariant(fontWeightBold, 42, 1.2, -1),
  h2: buildVariant(fontWeightLight, 36, 1.2, -1),
  h3: buildVariant(fontWeightMedium, 28, 1.3, -0.5),
  h4: buildVariant(fontWeightBold, 24, 1.5, -1),
  h5: buildVariant(fontWeightBold, 22, 1.5, 0),
  h6: buildVariant(fontWeightMedium, 20, 1.5, 0),
  subtitle1: buildVariant(fontWeightRegular, 16, 1.75, 0),
  subtitle2: buildVariant(fontWeightMedium, 14, 1.57, 0),
  body1: buildVariant(fontWeightRegular, 16, 1.5, 0),
  body2: buildVariant(fontWeightRegular, 14, 1.43, 0),
  button: buildVariant(fontWeightMedium, 14, 1.75, 0, noTransform),
  caption: buildVariant(fontWeightRegular, 12, 1.66, 0),
  overline: buildVariant(fontWeightRegular, 12, 2.66, 0, caseAllCaps),
}

const typography = {
  fontFamily,
  fontSize,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  fontWeightBlack,
  ...variants,
}

export default typography
