import AppBar from './AppBar'
import Button from './Button'
import { Container, ContainerSmall } from './Container'
import Drawer from './Drawer'
import Paper from './Paper'
import SnackbarContent from './SnackbarContent'


const components = {
  overrides: {
    MuiAppBar: AppBar,
    MuiButton: Button,
    MuiDrawer: Drawer,
    MuiPaper: Paper,
    MuiSnackbarContent: SnackbarContent,
  },
  container: Container,
  containerSmall: ContainerSmall,
}

export default components
