import React, { useEffect } from 'react'
import { CssBaseline, makeStyles } from '@material-ui/core'
// import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'theme/theme'
import Paperbase from 'Layout/Paperbase'
// import { firebase, db } from 'utils/fb'


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
  },
}))


function App() {
  const classes = useStyles()

  useEffect(() => {
    // db.collection("users").add({
    //   first: "Alan",
    //   middle: "Mathison",
    //   last: "Turing",
    //   born: 1912
    // })
    // .then(function (docRef) {
    //   console.log("Document written with ID: ", docRef.id)
    // })
    // .catch(function (error) {
    //   console.error("Error adding document: ", error)
    // })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Paperbase />
      </div>
    </ThemeProvider>
  )
}

export default App
