import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import MenuIcon from '@material-ui/icons/Menu'


const useStyles = makeStyles(theme => ({
  appbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  toolbar: {
    // gutters: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(1),
      },
    // },
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
}))

const Header = ({ onDrawerToggle }) => {
  const classes = useStyles()

  return (
    <AppBar
      className={classes.appbar}
      component="div"
      color="primary"
      position="static"
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center" spacing={1}>
          <Hidden smUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs>
            <Typography color="inherit" variant="h3">
              Authentication
            </Typography>
          </Grid>
          <Grid item>
            <Button className={classes.button} variant="outlined" color="inherit" size="small">
              Web setup
            </Button>
          </Grid>
          <Grid item>
            <Tooltip title="Help">
              <IconButton color="inherit">
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
}

export default Header