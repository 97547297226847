import { blueGrey, red } from '@material-ui/core/colors'


const palette = {
  type: 'light',
  primary: {
    light: '#369CFF',
    main: '#0081ff',
    dark: '#0070c9',
    contrastText: "#fff",
  },
  secondary: {
    light: '#40E68B',
    main: '#1FC96C',
    dark: '#097E3E',
    contrastText: "#fff",
  },
  error: red,
  common: {
    white: '#fff',
    black: '#033040'
  },
  grey: blueGrey,
  text: {
    primary: '#033040',
    secondary: '#3c596c',
    disabled: '#4D6471',
    hint: '#4D6471',
  },
  navy: {
    primary: '#033040',
    divider: 'rgba(255,255,255,0.15)',
    dark: '#02202A',
    text: 'rgba(255,255,255,0.6)'
  },
  divider: 'rgba(255,255,255,0.1)',
  background: {
    paper: '#fff',
    default: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  }
}

export default palette